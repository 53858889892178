"use strict";

const Fancybox = () => {
    const init = () => {
        $(document).ready(function () {

            //////////////////////////////////////////////
            // Fancybox
            //////////////////////////////////////////////
            $('[data-fancybox]').fancybox({
                showNavArrows: false
            });

            $('[data-fancybox-chili-preview]').fancybox({
                showNavArrows: false,
                buttons: [
                    'close'
                ]
            });
        });

    };

    return {
        init: init
    };
};

export default Fancybox;