"use strict";

const PortalUpload = () => {
    const init = () => {
        var MultiFileUploader = function (element, options) {
            this.options = options

            this.$container = $(element)
            this.$el = $('.content', element)
            this.$clickable = $('.clickable', element)
            this.$template = $('.template', element)

            var acceptedFiles = this.options.fileTypes
            if (acceptedFiles == '*')
                acceptedFiles = null

            this.dropzone = new Dropzone(this.$container.get(0), {
                url: window.location,
                clickable: this.$clickable.get(0),
                previewsContainer: this.$el.get(0),
                previewTemplate: this.$template.html(),
                acceptedFiles: acceptedFiles,
                maxFilesize: this.options.maxSize,
                paramName: 'file_data'
            })

            var self = this

            this.dropzone.on("error", function(file, error){
                console.log(error);

                var $preview = $(file.previewElement);

                $preview.closest('form').find('.btn').removeClass('loading').find('span').text('Upload');

                $.oc.flashMsg({
                    'text': 'Error uploading file. ' + error,
                    'class': 'error',
                    'interval': 3
                });
            })

            this.dropzone.on("sending", function(file, xhr, formData) {
                self.addExtraFormData(formData)
            })

            this.dropzone.on("success", function(file, response){
                self.updateFile(file, response)
            })

            this.$el.on('click', '.delete', function(){
                self.removeFile($(this))
            })
        }

        MultiFileUploader.prototype.addExtraFormData = function(formData) {
            this.$el.closest('form').find('.btn').addClass('loading').find('span').text('Uploading');

            var $content = this.$el.closest('.js-content-wrapper')

            // Reset height of parent
            var uploaded_files = $content.find('.dz-preview').length;
            var new_pad = 0;
            if(uploaded_files > 0) {
                new_pad = 30 + (uploaded_files * 22);
            }
            $content.css('padding-bottom',  new_pad + 'px');

            if (this.options.extraData) {
                $.each(this.options.extraData, function (name, value) {
                    formData.append(name, value)
                })
            }

            var $form = this.$el.closest('form')
            if ($form.length > 0) {
                $.each($form.serializeArray(), function (index, field) {
                    formData.append(field.name, field.value);
                })
            }
        }

        MultiFileUploader.prototype.updateFile = function(file, response) {
            var self = this
            var $preview = $(file.previewElement);

            $preview.closest('form').find('.btn').removeClass('loading').find('span').text('Upload');

            if (response.id) {
                $preview.data('id', response.id);
            }

            self.checkIfCanProceed(response);

            $.oc.flashMsg({
                'text': 'File Successfully Uploaded',
                'class': 'success',
                'interval': 3
            });
        }

        MultiFileUploader.prototype.removeFile = function($link) {
            var self = this,
                $preview = $link.closest('.dz-preview'),
                id = $preview.data('id'),
                $content = $link.closest('.js-content-wrapper')

            $preview.removeClass('dz-success').addClass('dz-removing')

            var request = this.$container.closest('.js-control-multi-file-uploader').request(this.options.handler, {
                data: {
                    id: id,
                    mode: 'delete',
                },
                redirect: false,
                success: function (files) {
                    $preview.remove()

                    // Reset height of parent
                    var uploaded_files = $content.find('.dz-preview').length;
                    var new_pad = 0;
                    if(uploaded_files > 0) {
                        new_pad = 30 + (uploaded_files * 21);
                    }
                    $content.css('padding-bottom',  new_pad + 'px');

                    self.checkIfCanProceed(files);

                    $.oc.flashMsg({
                        'text': 'File Successfully Removed',
                        'class': 'success',
                        'interval': 3
                    });
                }
            })
        }

        MultiFileUploader.prototype.checkIfCanProceed = function(response) {
            if(response.document_ready_for_shipping) {
                $('#go-to-shipping').removeClass('disabled').removeAttr("disabled");
            } else {
                $('#go-to-shipping').attr("disabled", 'disabled');
                $('#go-to-shipping').addClass('disabled');
            }
        }

        MultiFileUploader.DEFAULTS = {
            handler: 'onUpdateFile',
            maxSize: null,
            fileTypes: null
        }

        var old = $.fn.multiFileUploader

        $.fn.multiFileUploader = function (option) {
            return this.each(function () {
                var $this = $(this)
                var data  = $this.data('oc.multiFileUploader')
                var options = $.extend({}, MultiFileUploader.DEFAULTS, $this.data(), typeof option == 'object' && option)

                if (!data) $this.data('oc.multiFileUploader', (data = new MultiFileUploader(this, options)))
                if (typeof option == 'string') data[option].call($this)
            })
        }

        $.fn.multiFileUploader.Constructor = MultiFileUploader

        $.fn.multiFileUploader.noConflict = function () {
            $.fn.multiFileUploader = old
            return this
        }

        // ICON UPLOAD CONTROL DATA-API
        // ===============
        $(document).render(function(){
            $('[data-control=multi-file-uploader]').multiFileUploader()
        })
    };

    return {
        init: init
    };
};

export default PortalUpload;