"use strict";

const Accordions = () => {
    const init = () => {
        $(document).ready(function () {

            function initAccordions() {
                $('.js-acc-header').off('click');
                $('.js-acc-header').on('click', function (e) {

                    if ($(this).hasClass('open')) {
                        $(this).removeClass('open');
                        $(this).next().removeClass('open').stop().slideUp();
                        return;
                    }
                    if (!$(this).hasClass('keep-others-open')) {
                        $(this).parent().parent().find('.js-acc-header').removeClass('open').next().removeClass('open').slideUp();
                    }
                    e.preventDefault();
                    $(this).addClass('open');
                    $(this).next().addClass('open').stop().slideDown();
                });
            }

            initAccordions();
            $(window).on('resize', function() {
                initAccordions();
            });
        });
    };

    return {
        init: init
    };
};

export default Accordions;