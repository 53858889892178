"use strict";

const Modules = () => {
    const init = () => {
        $(document).ready(function() {

            // Blog Filter
            $('.js-filter-blog').click(function(){
                $('.js-filter-blog').removeClass('active');
                $(this).addClass('active');

                $('#categories').val($(this).attr('data-cat-value'));
                $('#ajax-submit').click();
            });

            // Services
            $('.js-services-mobile-nav').on('change', function () {
                window.location.href = $(this).val();
            });

            // Lazy load SVG images
            $('.svg-embed-image').each(function() {
                $(this).on("load", function () {
                    console.log('loaded');
                    $(this).removeAttr('data-href');
                });
                $(this).attr('href', $(this).attr('data-href'));
            });


        });
    };

    return {
        init:init
    };
};

export default Modules;