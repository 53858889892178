window.$ = window.jQuery = require('jquery');
require('./vendor/modernizr-custom');
require('./vendor/jquery-ui');
require('./vendor/jquery-validate');
require("@popperjs/core");
require ('bootstrap');
require('jquery-nice-select');
require("@fancyapps/fancybox");

import AOS from 'aos'
import 'aos/dist/aos.css'

import objectFitVideos from 'object-fit-videos';
import objectFitImages from 'object-fit-images';

import gaEvents from './vendor/ga-events';
import forms from './_forms';
import sliders from './_sliders';
import menu from './_menu';
import pagination from './_pagination';
import gMap from './_map';
import fancybox from './_fancybox';
import accordions from './_accordions';
import modules from './_modules';
import portal from './_portal';
import shop from './_shop';
import portalUpload from './_portal_file_upload';
import payment from './_payment';
import chili from './_chili';

$(function() { // Shorthand for $( document ).ready()
    "use strict";
    // Your js script is below this line
    // --------------------------------------------------------------------- //

    window.GA = new gaEvents();
    GA.init();

    window.Forms = new forms();
    Forms.init();

    window.Menu = new menu();
    Menu.init();

    window.Sliders = new sliders();
    Sliders.init();

    window.Pagination = new pagination();
    Pagination.init();

    window.GMap = new gMap();
    GMap.init();

    window.Fancybox = new fancybox();
    Fancybox.init();

    window.Accordions = new accordions();
    Accordions.init();

    window.Modules = new modules();
    Modules.init();

    window.Portal = new portal();
    Portal.init();

    window.PortalUpload = new portalUpload();
    PortalUpload.init();

    window.Shop = new shop();
    Shop.init();

    window.Payment = new payment();
    Payment.init();

    window.Chili = new chili();
    Chili.init();


    if ($('.js-home-header-slider-container .js-home-header-slider').length > 0) {
        $('.js-home-header-slider-container .js-home-header-slider').on('init', function () {
            initAos()
        })
    } else {
        initAos()
    }

    $(document).ready(function() {
        // WebP background image fallback swap
        const checkWebp = function () {
            if (!Modernizr.webp) {
                let $elements = $('*[data-webp-bg-fallback]');
                $elements.each(function() {
                    let fallback = $(this).data('webp-bg-fallback');
                    $(this).css('background-image', 'url(' + fallback + ')');
                });

                // Update img's with fallback
                let $images = $('*[data-webp-img-fallback]');
                $images.each(function() {
                    let fallback =  $(this).data('webp-img-fallback');
                    $(this).attr('src', fallback);
                });
            }
        };

        $(document).on('ajaxUpdate', function(event, context) {
            checkWebp();
        });
        checkWebp();

        $('html').addClass('ready');
    });

    // Sets inputs as filled when Google autocomplete prefills them
    $(window).on('load', function() {
        $('input:-webkit-autofill').each(function(){
            $(this).parents('.form-group').addClass('focused filled');
        });
    });

    objectFitVideos();
    objectFitImages('img');
});

function initAos () {
    return AOS.init({
        duration: 1000,
        delay: 300,
        once: true,
        mirror: true
    })
}