"use strict";

import helpers from "./_helpers";

const Menu = () => {
    const init = () => {
        $(document).ready(function () {

            // Menu Clickable
            $('.navbar-toggler').on('click', function () {
                $(this).toggleClass('open');
                $(this).closest('header').toggleClass('open');
            });

            // Menu open Mobile
            $('.js-open-menu').on('click', function (e) {
                e.preventDefault();
                if (window.innerWidth <= 991) {
                    if($(this).hasClass('js-mega-menu-toggle')) {
                        $('.js-mobile-mega-menu-container').toggleClass('open');
                    } else {
                        $(this).toggleClass('open');
                        $(this).next().toggleClass('open');
                    }
                }
            });

            // Mobile mega menu close
            $('.js-back-button').on('click', function (e) {
                e.preventDefault();
                $('.js-mobile-mega-menu-container').toggleClass('open');
            });

            // Mobile mega menu filters
            $('.js-category-selection').on('change', function (e) {
                e.preventDefault();

                // Show hide cats
                if($(this).val() == 'all') {
                    $('.js-tag-filter-mobile').addClass('show-tag');
                } else {
                    $('.js-tag-filter-mobile').removeClass('show-tag');
                    $(document).find(".js-tag-filter-mobile[data-cat-ids*='" + $(this).val() + "']").addClass('show-tag');
                }
            });

            // Mobile mega nav
            $('.js-services-mobile-nav').on('change', function () {
                window.location.href = $(this).val();
            });

            // Mega menu tabs
            // Initial load
            $('.js-mega-menu-tab-container').height($(".js-mega-menu-tab.active").height());

            $('.js-mega-menu-tab-toggle').on('click', function (e) {
                e.preventDefault();
                var $tab_to_show = $(".js-mega-menu-tab[data-tab=" + $(this).attr('data-tab') + "]");
                $('.js-mega-menu-tab-toggle').removeClass('active');
                $('.js-mega-menu-tab').removeClass('active');
                $(this).addClass('active');
                $tab_to_show.addClass('active');
                $('.js-mega-menu-tab-container').height($tab_to_show.height());
            });

            // Desktop tags selection
            $('.js-tag-filter-desktop').on('click', function (e) {
                e.preventDefault();

                // Tabs nav control
                var $tab_to_show = $(".js-mega-menu-tab[data-tab=" + $(this).attr('data-tab') + "]");
                $('.js-mega-menu-tab-toggle').removeClass('active');
                $('.js-mega-menu-tab').removeClass('active');
                $tab_to_show.addClass('active');
                $('.js-mega-menu-tab-container').height($tab_to_show.height());

                // Tags show hide
                $('.js-tag-filter-desktop').removeClass('active');
                $(this).addClass('active');

                // Show hide cats
                if($(this).attr('data-cat-id') == 'all') {
                    $('.js-tag-filter').addClass('show-tag');
                } else {
                    $('.js-tag-filter').removeClass('show-tag');
                    $(document).find(".js-tag-filter[data-cat-ids*='" + $(this).attr('data-cat-id') + "']").addClass('show-tag');
                }
            });

            // Menu open Desktop
            $('.js-open-menu').on('mouseenter', function (e) {
                if (window.innerWidth > 991 && !helpers.isTouchDevice()) {
                    $(this).addClass('open');
                    $(this).closest('header').toggleClass('open');
                    $(this).next().addClass('open');
                }
            }).on('mouseleave', function (e) {
                if (window.innerWidth > 991 && !helpers.isTouchDevice()) {
                    $(this).closest('header').toggleClass('open');
                    $(this).next().removeClass('open');
                    $(this).removeClass('open');
                }
            });

            $('.js-menu').on('mouseenter', function (e) {
                if (window.innerWidth > 991 && !helpers.isTouchDevice()) {
                    $(this).closest('header').toggleClass('open');
                    $(this).addClass('open');
                    $(this).prev().find('.js-open-menu').addClass('open');
                }
            }).on('mouseleave', function (e) {
                if (window.innerWidth > 991 && !helpers.isTouchDevice()) {
                    $(this).closest('header').toggleClass('open');
                    $(this).removeClass('open');
                    $(this).prev().find('.js-open-menu').removeClass('open');
                }
            });

            // detect scroll top or down
            if ($('.smart-scroll').length > 0) { // check if element exists

                // Reset scroll top so if it loads half way down a page it doenst assume its at the top
                var scroll_top = $(this).scrollTop();
                if(scroll_top != 0) {
                    $('.smart-scroll').removeClass('scrolled-top');
                    $('.js-home-arrow-down').removeClass('scrolled-top');
                }

                var last_scroll_top = 0;
                $(window).on('scroll', function() {
                    if(!$('.smart-scroll').hasClass('open')) {
                        var scroll_top = $(this).scrollTop();

                        // Caters for safari where the scroll top can be in the negative
                        if(last_scroll_top < 0) {
                            last_scroll_top = 0;
                        }

                        if(scroll_top <= 0) {
                            $('.smart-scroll').addClass('scrolled-top');
                        } else {
                            $('.smart-scroll').removeClass('scrolled-top');
                        }

                        last_scroll_top = scroll_top;
                    }
                });
            }

            $('.js-row-redirect').on('click', function () {
                window.location.href = $(this).attr('data-redirect-url');
            });

            // Sticky menu on scroll
            $(window).scroll(function(e){
                var $el = $('.js-fixed-scroll-menu');
                if (window.innerWidth > 974) { // lgbreakpoint but cater for scroll bar
                    var isPositionFixed = ($el.css('position') == 'fixed');
                    if ($(this).scrollTop() > 200 && !isPositionFixed) {
                        $el.css({'position': 'fixed', 'top': '100px'});
                    }
                    if ($(this).scrollTop() < 200 && isPositionFixed) {
                        $el.css({'position': 'absolute', 'top': '300px'});
                    }
                } else {
                    $el.css({'position': '', 'top': ''});
                }
            });


        });
    };

    return {
        init: init
    };
};

export default Menu;