"use strict";

import Aos from 'aos';

 require('slick-carousel');

const Sliders = () => {
    const init = () => {
        $(document).ready(function() {

            var $home_header_slider_container = $('.js-home-header-slider-container');
            if ($home_header_slider_container.length > 0) {
                const settings = {
                    infinite: true,
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    autoplay: false,
                    touchMove: true,
                    mobileFirst: true,
                    lazyLoad: 'progressive',
                    touchThreshold: 500,
                };

                $home_header_slider_container.each(function () {
                    var $home_header_slider = $(this).find('.js-home-header-slider').slick(settings);
                });
            }

            var $image_gallery_slider_container = $('.js-image-gallery-slider-container');
            if ($image_gallery_slider_container.length > 0) {
                const settings = {
                    infinite: false,
                    arrows: false,
                    dots: false,
                    slidesToShow: 1,
                    autoplay: false,
                    touchMove: true,
                    mobileFirst: true,
                    lazyLoad: 'progressive',
                    touchThreshold: 500,
                    responsive: [
                        {
                            breakpoint: 776,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 1921,
                            settings: {
                                slidesToShow: 3
                            }
                        }
                    ]
                };

                $image_gallery_slider_container.each(function () {
                    var $image_gallery_slider = $(this).find('.js-image-gallery-slider').slick(settings);
                });
            }

        });
    };

    return {
        init:init
    };
};

export default Sliders;