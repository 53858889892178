"use strict";

const Payment = () => {
    const init = () => {
        $(document).ready(function () {
            initCheckout();
        });
    };

    const checkIfComplete = () => {
        var can_proceed = false;

        if($('#cardNumber').hasClass('StripeElement--complete') &&
            $('#cardExpiry').hasClass('StripeElement--complete') &&
            $('#cardCVV').hasClass('StripeElement--complete')) {
            can_proceed = true
        }

        if(can_proceed) {
            $('.js-information-error-message').hide();
            $('.js-submit-payment').removeClass('disabled');
        } else {
            $('.js-information-error-message').show();
            $('.js-submit-payment').addClass('disabled');
        }
    }

    const initCheckout = () => {
        // Stripe.js instructions taken from https://stripe.com/docs/payments/cards/collecting
        let form = document.getElementById('stripe-payment-form');
        if (form) {
            window.setInterval(checkIfComplete, 500);

            // Setup stripe.js
            let stripe_publishable_key = $('#stripe_publishable_key').val();
            if (stripe_publishable_key !== '') {
                let stripe = Stripe(stripe_publishable_key);
                let elements = stripe.elements();

                let elementClasses = {
                    focus: 'focused',
                    empty: 'empty',
                    invalid: 'invalid',
                };
                let elementStyles = {
                    base: {
                        color: '#475569',
                        fontFamily: '"urbane", sans-serif',
                        fontSmoothing: 'antialiased',
                        fontWeight: '300',
                        fontSize: '16px',
                        lineHeight: '30px',
                        '::placeholder': {
                            color: '#475569'
                        },
                        ":-webkit-autofill": {
                            color: "#475569"
                        }
                    },
                    invalid: {
                        color: '#dc3545',
                        iconColor: '#dc3545'
                    }
                };

                let cardNumber = elements.create('cardNumber', {
                    style: elementStyles,
                    classes: elementClasses,
                }),
                cardExpiry = elements.create('cardExpiry', {
                    style: elementStyles,
                    classes: elementClasses,
                }),
                cardCvc = elements.create('cardCvc', {
                    style: elementStyles,
                    classes: elementClasses,
                });

                cardNumber.mount('#cardNumber');
                cardExpiry.mount('#cardExpiry');
                cardCvc.mount('#cardCVV');

                const stripeTokenHandler = (token) => {
                    var hiddenInput = document.createElement('input');
                    hiddenInput.setAttribute('type', 'hidden');
                    hiddenInput.setAttribute('name', 'stripeToken');
                    hiddenInput.setAttribute('value', token.id);
                    form.appendChild(hiddenInput);

                    // We have a stripe token so submit the form
                    $('form').request('MakeOrder::onCreate', {
                        data: {
                            stripeToken: token.id,
                            'order': {
                                'payment_method_id': $('input[name="payment_method_type"]:checked').val()
                            }
                        },
                        success: function (result_data) {
                            if (result_data['message']) {
                                $.oc.flashMsg({
                                    'text': result_data['message'],
                                    'class': 'error',
                                    'interval': 3
                                });
                                $('.js-submit-payment').removeClass('loading');
                                $('.js-submit-payment').attr('disabled', false);
                            }

                            this.success(result_data);
                        },
                        error: function (result_data) {
                            $('.js-submit-payment').removeClass('loading');
                            $('.js-submit-payment').attr('disabled', false);

                            $.oc.flashMsg({
                                'text': 'Error making payment. Code 901.',
                                'class': 'error',
                                'interval': 3
                            });
                        }
                    });
                };

                form.addEventListener('submit', function(e) {
                    e.preventDefault();
                    e.stopPropagation();

                    $('.js-submit-payment').addClass('loading');
                    $('.js-submit-payment').attr('disabled', true);

                    let cartName = $(form).find("input[name='cardName']").val(),
                        tokenData = {
                            name: cartName
                        };

                    stripe.createToken(cardNumber, tokenData).then(function(result) {
                        if (result.error) {
                            // Inform the customer that there was an error.
                            var errorElement = document.getElementById('card-errors');
                            errorElement.textContent = result.error.message;
                            errorElement.style.display = "block";

                            $('.js-submit-payment').removeClass('loading');
                            $('.js-submit-payment').attr('disabled', false);
                        } else {
                            // Send the token to your server.
                            stripeTokenHandler(result.token);
                        }
                    });
                });
            }
        }
    };

    return {
        init: init,
        initCheckout:initCheckout
    };
};

export default Payment;