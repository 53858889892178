"use strict";

const helpers = {
    setUrlParameter: (key, value) => {
        let url = new URL(window.location.href);
        let query_string = url.search;
        let search_params = new URLSearchParams(query_string);

        search_params.set(key, value);
        url.search = search_params.toString();

        window.history.pushState({}, "", url);
    },
    deleteUrlParameter: (key) => {
        let url = new URL(window.location.href);
        let query_string = url.search;
        let search_params = new URLSearchParams(query_string);

        search_params.delete(key);
        url.search = search_params.toString();

        window.history.pushState({}, "", url);
    },
    getUrlParameter: (name, url) => {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    isTouchDevice: () => {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    },
    validateFields: ($element_to_validate) => {
        var validator = $element_to_validate.validate({
            ignore: ":hidden, .ignore",
            errorElement: "p",
            errorClass: "form-field-error-label",
            highlight: function(element) {
                $(element).parents('.form-group').addClass("has-error");
            },
            unhighlight: function(element) {
                $(element).parents('.form-group').removeClass("has-error");
            },
            errorPlacement: function(error, element) {
                if (element.hasClass("nice-select")) {
                    error.insertAfter(element.next("div.nice-select"));
                } else {
                    error.insertAfter(element);
                }
            },


            // errorLabelContainer: "#validation-errors",
            // wrapper: "li",
            // showErrors: function(errorMap, errorList) {
            //     $("#validation-errors").html("Your form contains " + this.numberOfInvalids() + " errors, see details below.");
            //     $("#validation-errors").addClass('active');
            //     this.defaultShowErrors();
            // }
        });

        return validator;
    }
};

export default helpers;