"use strict";

import helpers from "./_helpers";

const Shop = () => {
    const init = () => {
        $(document).ready(function () {
            // Add product to cart from portal and my estimate page
            $(document).on('click','#add-to-cart', function (e) {
                e.preventDefault();
                var $button = $(this);

                addToCart($button).then(function(data) {
                    window.onbeforeunload = null;
                    window.location.href = '/cart';
                }).catch(function(err) {
                    // Run this when promise was rejected via reject()
                    console.log(err)
                }).finally(function(err) {
                    $button.removeClass('loading');
                });
            });

            function addToCart ($button) {
                var product_id = $button.attr('data-pid');
                var estimate_id = $button.attr('data-estimate-id');
                var copy_estimate = $button.attr('data-copy');

                $button.attr('disabled', 'disabled').addClass('loading');

                return new Promise(function(resolve, reject) {
                    var error_message = '';

                    if(product_id && product_id.length > 0 && estimate_id && estimate_id.length > 0) {
                        $.request('PrintPortal::onAddToCart', {
                            data: {
                                'product_id': product_id,
                                'estimate_id' : estimate_id,
                                'copy_estimate' : copy_estimate
                            },
                            update: {
                                'portal/portal_cart_count': '.js-portal-cart-count-container'
                            },
                            success: function (result_data) {
                                if (result_data['added_to_cart']) {

                                    $.oc.flashMsg({
                                        'text': result_data['success_message'],
                                        'class': 'success',
                                        'interval': 3
                                    });

                                    var on_portal_page = $button.attr('data-on-portal-page');
                                    if(on_portal_page && on_portal_page.length > 0) {
                                        // TODO: reset form or redirect maybe?
                                        $('#generate-product-estimate').removeClass('secondary');
                                        $button.addClass('secondary');
                                    } else {
                                        $button.removeAttr('disabled');
                                    }

                                    this.success(result_data);
                                    resolve(result_data);
                                } else {
                                    if (result_data['error_message'] !== undefined) {
                                        $.oc.flashMsg({
                                            'text': result_data['error_message'],
                                            'class': 'error',
                                            'interval': 3
                                        });
                                    }
                                    $button.removeAttr('disabled');
                                    reject(result_data);
                                }
                            },
                            error: function (data) {
                                // Remove navigation prompt
                                 window.onbeforeunload = null;

                                error_message = 'Error adding product. Code 05.'

                                $.oc.flashMsg({
                                    'text': error_message,
                                    'class': 'error',
                                    'interval': 3
                                });

                                $button.removeAttr('disabled');

                                reject(data);
                            }
                        })
                    } else {
                        $button.removeAttr('disabled').removeClass('loading');

                        error_message = 'Error adding product. Code 08.'

                        $.oc.flashMsg({
                            'text': error_message,
                            'class': 'error',
                            'interval': 3
                        });
                    }
                });
            }

            //Remove offer from cart
            $(document).on('click','.js-delete-offer-from-cart', function (e) {
                e.preventDefault();
                var $button = $(this);

                $("#js-confirm-delete-product").dialog({
                    resizable: false,
                    height: "auto",
                    minWidth: 370,
                    modal: true,
                    draggable: false,
                    buttons: {
                        "Delete Item": function() {
                            $(this).dialog("close");
                            $('body').removeClass('display-dialog');

                            removeFromCart($button).then(function(data) {
                                console.log(data);
                            }).catch(function(err) {
                                // Run this when promise was rejected via reject()
                                console.log(err)
                            }).finally(function(err) {

                            });
                        },
                        Cancel: function() {
                            $(this).dialog("close");
                            $('body').removeClass('display-dialog');
                            e.preventDefault();
                        }
                    }
                });
            });

            function removeFromCart ($button) {
                var position_id = $button.attr('data-position_id');

                return new Promise(function(resolve, reject) {
                    if(position_id && position_id.length > 0) {
                        $.request('PrintPortalExtras::onDeleteFromCart', {
                            data: {'position_id': [position_id]},
                            update: {
                                'portal/portal_cart_count': '.js-portal-cart-count-container',
                                'shop/cart/cart_item_list': '.js-cart-container',
                                'shop/cart/totals/cart_total_cost': '.js-cart-total-subtotal-cost'
                            },
                            success: function (data) {
                                $.oc.flashMsg({
                                    'text': 'Successfully removed from cart',
                                    'class': 'success',
                                    'interval': 3
                                });
                                this.success(data);
                                resolve(data);
                            },
                            error: function (data) {
                                $.oc.flashMsg({
                                    'text': 'Error removing product. Code 09.',
                                    'class': 'error',
                                    'interval': 3
                                });
                                reject(data);
                            }
                        })
                    }
                });
            }

            // Add product to cart
            $(document).on('click','#go-to-shipping', function (e) {
                e.preventDefault();

                goToShipping().then(function (data) {
                      window.location.href = '/shipping';
                }).catch(function (err) {
                    // Run this when promise was rejected via reject()
                    console.log(err)
                    $('#go-to-shipping').removeAttr('disabled').removeClass('loading');
                }).finally(function (err) {

                });
            });

            function goToShipping () {
                $('#go-to-shipping').attr('disabled', 'disabled').addClass('loading');

                return new Promise(function(resolve, reject) {
                    // Check if notes or PO number have been modified
                    if($('#notes').attr('data-original-text') != $('#notes').val() || $('#ponumber').attr('data-original-text') != $('#ponumber').val()) {
                        $.request('PrintPortalExtras::onGoToShipping', {
                            data: {
                                'notes': $('#notes').val(),
                                'ponumber': $('#ponumber').val()
                            },
                            success: function (result_data) {
                                if (result_data['order_saved']) {
                                    this.success(result_data);
                                    resolve(result_data);
                                } else {
                                    if (result_data['error_message'] !== undefined) {
                                        $.oc.flashMsg({
                                            'text': 'Error. Please try again.',
                                            'class': 'error',
                                            'interval': 3
                                        });
                                    }
                                    reject(result_data);
                                }
                            },
                            error: function (data) {
                                   $.oc.flashMsg({
                                    'text': 'Error. Please try again.',
                                    'class': 'error',
                                    'interval': 3
                                });

                                reject(data);
                            }
                        })
                    } else {
                        resolve('');
                    }
                });
            }

            // Toggle pick up or not
            $(document).on('click','.js-toggle-pick-up', function (e) {
                var $courier_drop_down = $(this).parents('.js-table').find('.js-courier-list');
                var $address_drop_down = $(this).parents('.js-table').find('.js-shipping-address');

                if($(this).is(':checked')){
                    $courier_drop_down.attr("disabled", 'disabled');
                    $courier_drop_down.addClass('disabled').parents('.form-group').addClass('disabled');
                    $address_drop_down.parents('.js-account-table-row').find('.js-new-address-fields').removeClass('open').find('input').addClass('ignore');
                    enableClickCollectMessage($address_drop_down);
                } else {
                    $courier_drop_down.removeAttr("disabled");
                    $courier_drop_down.removeClass('disabled').parents('.form-group').removeClass('disabled');
                    disableClickCollectMessage($address_drop_down);

                    if($address_drop_down.val() == 'new') {
                        $address_drop_down.parents('.js-account-table-row').find('.js-new-address-fields').addClass('open').find('input').removeClass('ignore');
                    }
                }
                updatePrices();
            });

            // Toggle shipping to separate addresses
            $(document).on('click','.js-toggle-separate-shipping', function (e) {
                var $pick_up_toggle = $(this).parents('.js-table').find('.js-toggle-pick-up');
                var $pick_up_toggle_text = $(this).parents('.js-table').find('.js-pick-text');
                var $pick_up_toggle_label = $(this).parents('.js-table').find('.js-toggle-pick-up-label');
                var $address_drop_down = $(this).parents('.js-table').find('.js-shipping-address');
                var $courier_drop_down = $(this).parents('.js-table').find('.js-courier-list');
                var table_rows = $('.js-shipping-details-container .js-account-table-row');

                if($(this).is(':checked')){
                    $pick_up_toggle.attr("disabled", 'disabled');
                    $pick_up_toggle_text.attr("disabled", 'disabled');
                    $pick_up_toggle.addClass('disabled').parents('.form-group').addClass('disabled');
                    $pick_up_toggle_label.addClass('disabled');
                    $address_drop_down.attr("disabled", 'disabled');
                    $address_drop_down.addClass('disabled').parents('.form-group').addClass('disabled');
                    $address_drop_down.parents('.js-account-table-row').find('.js-new-address-fields').removeClass('open').find('input').addClass('ignore');
                    $('#go-to-payment').removeAttr('disabled');

                    if(!$courier_drop_down.parents('.form-group').hasClass('no-options')) {
                        $courier_drop_down.attr("disabled", 'disabled');
                        $courier_drop_down.addClass('disabled').parents('.form-group').addClass('disabled');
                    }

                    table_rows.each(function() {
                        $(this).removeClass('disabled');
                        var $sub_address_drop_down = $(this).find('.js-shipping-address');
                        var $sub_courier_drop_down = $(this).find('.js-courier-list');
                        $sub_address_drop_down.removeAttr('disabled');
                        $sub_address_drop_down.removeClass('disabled').parents('.form-group').removeClass('disabled');

                        if($sub_address_drop_down.val() == 'new') {
                            $sub_address_drop_down.parents('.js-account-table-row').find('.js-new-address-fields').addClass('open').find('input').removeClass('ignore');
                            $('#go-to-payment').attr('disabled', 'disabled');
                        }

                        if(!$sub_courier_drop_down.parents('.form-group').hasClass('no-options')) {
                            $sub_courier_drop_down.removeAttr("disabled");
                            $sub_courier_drop_down.removeClass('disabled').parents('.form-group').removeClass('disabled');
                        }
                    });
                } else {
                    $pick_up_toggle.removeAttr("disabled");
                    $pick_up_toggle_text.removeAttr("disabled");
                    $pick_up_toggle.removeClass('disabled').parents('.form-group').removeClass('disabled');
                    $pick_up_toggle_label.removeClass('disabled');
                    $address_drop_down.removeAttr("disabled");
                    $address_drop_down.removeClass('disabled').parents('.form-group').removeClass('disabled');

                    if($address_drop_down.val() == 'new') {
                        $address_drop_down.parents('.js-account-table-row').find('.js-new-address-fields').addClass('open').find('input').removeClass('ignore');
                        $('#go-to-payment').attr('disabled', 'disabled');
                    } else {
                        $address_drop_down.parents('.js-account-table-row').find('.js-new-address-fields').removeClass('open').find('input').addClass('ignore');
                        $('#go-to-payment').removeAttr('disabled');
                    }

                    if(!$courier_drop_down.parents('.form-group').hasClass('no-options')) {
                        if ($pick_up_toggle.is(':checked')) {
                            $courier_drop_down.attr("disabled", 'disabled');
                            $courier_drop_down.addClass('disabled').parents('.form-group').addClass('disabled');
                        } else {
                            $courier_drop_down.removeAttr("disabled");
                            $courier_drop_down.removeClass('disabled').parents('.form-group').removeClass('disabled');
                        }
                    }

                    table_rows.each(function() {
                        $(this).addClass('disabled');
                        $('#go-to-payment').removeAttr('disabled');
                        var $sub_address_drop_down = $(this).find('.js-shipping-address');
                        var $sub_courier_drop_down = $(this).find('.js-courier-list');
                        $sub_address_drop_down.attr("disabled", 'disabled');
                        $sub_address_drop_down.addClass('disabled').parents('.form-group').addClass('disabled');
                        $sub_address_drop_down.parents('.js-account-table-row').find('.js-new-address-fields').removeClass('open').find('input').addClass('ignore');

                        if(!$sub_courier_drop_down.parents('.form-group').hasClass('no-options')) {
                            $sub_courier_drop_down.attr("disabled", 'disabled');
                            $sub_courier_drop_down.addClass('disabled').parents('.form-group').addClass('disabled');
                        }
                    });
                }

                updatePrices();
            });

            // Add new address
            $(document).on('click','.js-add-new-address', function (e) {
                e.preventDefault();

                // Clear old validator
                var validator = $("#shipping-form").validate();
                validator.destroy();

                // Put in new one
                var form_validator = helpers.validateFields($("#shipping-form"));
                var form_is_valid = $("#shipping-form").valid();

                if(!form_is_valid) {
                    var error_count = form_validator.numberOfInvalids();

                    $.oc.flashMsg({
                        'text': 'There ' + (error_count == 1 ? 'is' : 'are') + ' ' + error_count + ' error' + (error_count == 1 ? '' : 's') + ' in the form. Please fill in all required fields and try again.',
                        'class': 'error',
                        'interval': 3
                    });
                } else {
                    var add_button = $(this);
                    addNewAddress(add_button).then(function(data) {
                        console.log(data);
                    }).catch(function(err) {
                        // Run this when promise was rejected via reject()
                        console.log(err)
                    }).finally(function(err) {
                        add_button.removeAttr('disabled').removeClass('loading');
                    });
                }
            });

            function addNewAddress (add_button) {
                add_button.attr('disabled', 'disabled').addClass('loading');

                return new Promise(function(resolve, reject) {
                    var error_message = '';
                    var form_container = add_button.parents('.js-new-address-fields');

                    var form_data = [];

                    form_container.find('input, select').each(function () {
                        form_data[this.name] = $(this).val();
                    });

                    $.request('PortalShipping::onAddNewAddress', {
                        data: form_data,
                        success: function (result_data) {
                            if (result_data['new_address_added']) {

                                $.oc.flashMsg({
                                    'text': 'Successfully added new address',
                                    'class': 'success',
                                    'interval': 3
                                });

                                // Update all address drop downs to have the new address
                                $('.js-shipping-address').each(function() {
                                    $(this).niceSelect('destroy');
                                    var $option = $('<option>').attr('value', result_data['shipping_address_id']).text(result_data['shipping_address']);

                                    // Add second last as we want it before the add new option
                                    var $second_last = $(this).find('option:last-child').prev();
                                    $($option).insertAfter($second_last);

                                    // Remove default text if its present as we now have an address
                                    $(this).find("option[value='select']").remove();

                                    $(this).niceSelect();

                                    // Only 1 address and add new so set the adddress it as default
                                    if($(this).find('option').length == 2) {
                                        $(this).val(result_data['shipping_address_id']);
                                        $(this).niceSelect('update');
                                        $(this).change();
                                    }
                                });

                                // select current drop down to have the new address
                                var $address_list = add_button.parents('.js-account-table-row').find('.js-shipping-address');
                                $address_list.val(result_data['shipping_address_id']);
                                $address_list.niceSelect('update');
                                $address_list.change();

                                // Show courier drop down as you just selected an address
                                var $courier_list = add_button.parents('.js-account-table-row').find('.js-courier-list');
                                $courier_list.parents('.form-group').removeClass('disabled');

                                add_button.parents('.js-account-table-row').find('.js-new-address-fields').removeClass('open');

                                form_container.find('input').each(function () {
                                    $(this).val('');
                                });

                                this.success(result_data);
                                resolve(result_data);
                            } else {
                                $.oc.flashMsg({
                                    'text': 'Error saving address. Please complete all required fields and try again.',
                                    'class': 'error',
                                    'interval': 3
                                });
                                reject(result_data);
                            }
                        },
                        error: function (data) {
                            $.oc.flashMsg({
                                'text': 'Error adding product. Code 70',
                                'class': 'error',
                                'interval': 3
                            });

                            reject(data);
                        }
                    })
                });
            }

            // calculate shipping cost and available couriers
            $(document).on('change','.js-shipping-address', function (e) {
                if($(this).val() == 'new') {
                    $(this).parents('.js-account-table-row').find('.js-new-address-fields').addClass('open').find('input').removeClass('ignore');
                    $('#go-to-payment').attr('disabled', 'disabled');
                } else {
                    $('#go-to-payment').removeAttr('disabled');
                    $(this).parents('.js-account-table-row').find('.js-new-address-fields').removeClass('open').find('input').addClass('ignore');
                    $('body').addClass('loading');

                    generateCouriers($(this),'',$(this).attr('data-allow-pickup')).then(function(data) {

                    }).catch(function(err) {
                        // Run this when promise was rejected via reject()
                        console.log(err)
                    }).finally(function(err) {
                        $('body').removeClass('loading');
                    });
                }
            });

            $(document).on('change','.js-courier-list', function (e) {
                // Do click collect message for only cart rows and not header row
                if($(this).hasClass('js-cart-row')) {
                    var $address_drop_down = $(this).parents('.js-account-table-row').find('.js-shipping-address');

                    if($(this).val() == 0) {
                        enableClickCollectMessage($address_drop_down);
                    }else {
                        disableClickCollectMessage($address_drop_down);
                    }
                }

                updatePrices();
            });

            // Go to payment screen
            $(document).on('click','#go-to-payment', function (e) {
                e.preventDefault();
                goToPayment().then(function (data) {
                    window.location.href = '/payment';
                }).catch(function (err) {
                    // Run this when promise was rejected via reject()
                    console.log(err)
                    $('#go-to-payment').removeAttr('disabled').removeClass('loading');
                }).finally(function (err) {

                });
            });

            function goToPayment () {
                $('#go-to-payment').attr('disabled', 'disabled').addClass('loading');

                return new Promise(function(resolve, reject) {
                    var is_separate_shipping = ($('.js-shipping-selector-container .js-toggle-separate-shipping').is(':checked') ? 'true' : 'false');
                    var shipping_cost = 0;
                    var courier_id = $('.js-shipping-selector-container .js-courier-list').val();

                    // Shipping is not pick up so send price
                    if(courier_id != 0) {
                        var price_data = $('.js-shipping-selector-container .js-courier-list').attr('data-price-info');

                        price_data = JSON.parse(price_data);
                        shipping_cost = price_data[courier_id];
                    }

                    var shipping_data = {
                        'shipping_address_id' : $('.js-shipping-selector-container .js-shipping-address').val(),
                        'courier_id' : courier_id,
                        'courier_cost' : shipping_cost,
                        'is_pick_up' : ($('.js-shipping-selector-container .js-toggle-pick-up').is(':checked') ? 'true' : 'false'),
                        'is_separate_shipping' : is_separate_shipping,
                    };

                    if(is_separate_shipping == 'true') {
                        var table_rows = $('.js-shipping-details-container .js-account-table-row');
                        var separate_shipping_details = [];

                        table_rows.each(function() {
                            var $address_drop_down = $(this).find('.js-shipping-address');
                            var $courier_drop_down = $(this).find('.js-courier-list');

                            var shipping_cost = 0;
                            var courier_id = $courier_drop_down.val();

                            // Shipping is not pick up so send price
                            if(courier_id != 0) {
                                var price_data = $courier_drop_down.attr('data-price-info');

                                price_data = JSON.parse(price_data);
                                shipping_cost = price_data[courier_id];
                            }

                            var shipping_details = {
                                'position_id' : $(this).attr('data-position-id'),
                                'courier_id' : courier_id,
                                'courier_cost' : shipping_cost
                            };

                            // Shipping is not pick up so send address_id
                            if(courier_id != 0) {
                                shipping_details['shipping_address_id'] = $address_drop_down.val();
                            }

                            separate_shipping_details.push(shipping_details);
                        });

                        shipping_data['separate_shipping_lines'] = separate_shipping_details
                    }

                    $.request('PortalShipping::onGoToPayment', {
                        data: shipping_data,
                        success: function (result_data) {
                            if (result_data['order_saved']) {
                                this.success(result_data);
                                resolve(result_data);
                            } else {
                                if (result_data['error_message'] !== undefined) {
                                    $.oc.flashMsg({
                                        'text': 'Error. Please try again.',
                                        'class': 'error',
                                        'interval': 3
                                    });
                                }
                                reject(result_data);
                            }
                        },
                        error: function (data) {
                            $.oc.flashMsg({
                                'text': 'Error. Please try again.',
                                'class': 'error',
                                'interval': 3
                            });

                            reject(data);
                        }
                    })
                });
            }

            // Change payment type
            $(document).on('click','.js-payment-type-toggle', function () {
                $('.js-payment-option').removeClass('active');
                $(document).find("[data-payment-id='" + $(this).val() + "']").toggleClass('active');
            });

            // Make payment via account
            $(document).on('click','#account-payment-button', function (e) {
                $('#account-payment-button').attr('disabled', 'disabled').addClass('loading');
                e.preventDefault();

                var data = {
                    'order': {
                        'payment_method_id': $('input[name="payment_method_type"]:checked').val(),
                    },
                };

                $('form').request('MakeOrder::onCreate', {
                    data: data,
                    success: function (result_data) {
                        $('#account-payment-button').removeAttr('disabled').removeClass('loading');
                        this.success(result_data);
                    },
                    error: function (result_data) {
                        $('#account-payment-button').removeAttr('disabled').removeClass('loading');
                        console.log(result_data);

                        $.oc.flashMsg({
                            'text': 'Error making payment. Code 900.',
                            'class': 'error',
                            'interval': 3
                        });
                    }
                });
            });







        });

        window.generateCouriers = function($address_drop_down, default_value = 0, allow_pickup = '', initial_load = 'false'){
            $('#go-to-payment').attr('disabled', 'disabled');

            return new Promise(function(resolve, reject) {
                var error_message = '';

                $.request('PortalShipping::onGenerateCouriers', {
                    data: {
                        address_id: $address_drop_down.val(),
                        position_id: $address_drop_down.parents('.js-account-table-row').attr('data-position-id'),
                    },
                    success: function (result_data) {
                        if (result_data['couriers_generated']) {

                            var $courier_list = $address_drop_down.parents('.js-account-table-row').find('.js-courier-list');

                            $courier_list.attr('data-price-info', JSON.stringify(result_data['courier_price_list']));

                            $courier_list.niceSelect('destroy');
                            $courier_list.find('option').remove().end();
                            $courier_list.attr("disabled", 'disabled');

                            var courier_list_array = JSON.parse(result_data['courier_list']);

                            if(courier_list_array.length > 0) {
                                for (var key in courier_list_array) {
                                    for (var sub_key in courier_list_array[key]) {
                                        var $option = $('<option>').attr('value', sub_key).text(courier_list_array[key][sub_key]);

                                        if(default_value == sub_key) {
                                            $option.attr('selected', 'selected');
                                        }

                                        $courier_list.append($option);
                                    }
                                }

                                if(allow_pickup == 'true') {
                                    var $option = $('<option>').attr('value', '0').text('Pickup (Free)');

                                    // if(default_value == '0') {
                                    //     $option.attr('selected', 'selected');
                                    // }
                                    $courier_list.append($option);
                                }
                                $courier_list.parents('.form-group').removeClass('no-options');

                                if(initial_load != 'true') {
                                    $courier_list.parents('.form-group').removeClass('disabled');
                                }
                            } else {
                                var $option = $('<option>').attr('value', 'select').text('Add a valid Address to select courier');
                                $courier_list.append($option);
                                $courier_list.parents('.form-group').addClass('no-options');
                            }

                            if(!$courier_list.parents('.form-group').hasClass('disabled')) {
                                $courier_list.removeAttr("disabled");
                            }

                            $courier_list.niceSelect();

                            updatePrices();

                            this.success(result_data);
                            resolve(result_data);
                        } else {
                            if (result_data['error_message'] !== undefined) {
                                $.oc.flashMsg({
                                    'text': result_data['error_message'],
                                    'class': 'error',
                                    'interval': 3
                                });
                            }
                            reject(result_data);
                        }
                    },
                    error: function (data) {
                        error_message = 'Error setting couriers. Code 61.'
                        $.oc.flashMsg({
                            'text': error_message,
                            'class': 'error',
                            'interval': 3
                        });
                        reject(data);
                    }
                })
            });
        }

        window.updatePrices = function(){
            var total_price = $('.js-cart-total').attr('data-base-price');
            var shipping_price = 0;
            var $courier_drop_down = $('.js-shipping-selector-container .js-courier-list');
            var $address_drop_down = $('.js-shipping-selector-container .js-shipping-address');
            var is_pick_up = ($('.js-shipping-selector-container .js-toggle-pick-up').is(':checked') ? 'true' : 'false');
            var is_separate_shipping = ($('.js-shipping-selector-container .js-toggle-separate-shipping').is(':checked') ? 'true' : 'false');
            $('#go-to-payment').removeAttr('disabled');

            if(is_separate_shipping == 'true') {
                $('.js-shipping-details-container .js-account-table-row').each(function() {
                    var $courier_drop_down = $(this).find('.js-courier-list');
                    var $cost_span = $(this).find('.js-cart-shipping-cost');
                    var $address_drop_down = $(this).find('.js-shipping-address');
                    var courier_id = $courier_drop_down.val();

                    // No valid couriers returned
                    if(courier_id == 'select') {
                        $cost_span.text('-');
                        $('#go-to-payment').attr('disabled', 'disabled');
                    } else {
                        // 0 is hard coded for pick up from store so only increase if its not 0. otherwise leave price as is
                        if(courier_id != '0') {
                            if (typeof $courier_drop_down.attr('data-price-info') !== typeof undefined && $courier_drop_down.attr('data-price-info') !== false) {
                                var price_json = JSON.parse($courier_drop_down.attr('data-price-info'));
                                shipping_price = shipping_price + +price_json[courier_id];
                                $cost_span.text('$' + numberWithCommas(price_json[courier_id]));
                            } else {
                                $cost_span.text('-');
                            }
                        } else {
                            $cost_span.text('$0');
                        }
                    }
                });

                total_price = +total_price + +shipping_price;

            } else {
                if(is_pick_up == 'true') {
                    $('.js-cart-total-subtotal-cost-span').text('$' + numberWithCommas(total_price));
                    $('.js-cart-total-shipping-cost').text('$' + 0);
                } else {
                    // No valid couriers returned
                    if($courier_drop_down.val() == 'select') {
                        $('#go-to-payment').attr('disabled', 'disabled');
                    } else {
                        if (typeof $courier_drop_down.attr('data-price-info') !== typeof undefined
                            && $courier_drop_down.attr('data-price-info') !== false
                            && typeof $courier_drop_down.val() !== typeof undefined)
                        {
                            var price_json = JSON.parse($courier_drop_down.attr('data-price-info'));
                            shipping_price = price_json[$courier_drop_down.val()];
                        } else {
                            shipping_price = 0;
                        }
                    }

                    total_price = +total_price + +shipping_price;
                }

                $('.js-shipping-details-container .js-account-table-row').each(function() {
                    var $cost_span = $(this).find('.js-cart-shipping-cost');
                    $cost_span.text('—');
                });
            }

             $('.js-cart-total-subtotal-cost-span').text('$' + numberWithCommas(Number(total_price).toFixed(2)));
             $('.js-cart-total-shipping-cost').text('$' + numberWithCommas(Number(shipping_price).toFixed(2)));
        }

        window.enableClickCollectMessage = function($address_drop_down){
            var click_collect_text = 'Click and Collect WHO Printing 8/5-7 Channel Rd MAYFIELD WEST NSW 2304';
            var current = $address_drop_down.find('.current');
            current.attr('data-current-address', current.text());
            current.text(click_collect_text);
            $address_drop_down.attr("disabled", 'disabled');
            $address_drop_down.addClass('disabled-looks-active').parents('.form-group').addClass('disabled-looks-active');
        }

        window.disableClickCollectMessage = function($address_drop_down){
            var current = $address_drop_down.find('.current');

            if(current.attr('data-current-address') !== undefined && current.attr('data-current-address').length > 0) {
                current.text(current.attr('data-current-address'));
            }

            current.attr('data-current-address', '');
            $address_drop_down.removeAttr("disabled");
            $address_drop_down.removeClass('disabled-looks-active').parents('.form-group').removeClass('disabled-looks-active');
        }

        window.numberWithCommas = function(number){
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    };

    return {
        init: init
    };
};

export default Shop;