"use strict";

import helpers from "./_helpers";

const Portal = () => {
    const init = () => {

        var product_loaded_id = null;

        $(document).ready(function() {
            blockMobileScreens();

            if($('.js-portal-details-container').length) {
                loadPortal();
            }

            // Checks validation on drop down when it is changed
            $(document).on('change','.portal-styles .nice-select', function (e) {
                $(this).valid();

               // Add class to use for styling on empty field
                if($(this).val()) {
                   if($(this).val().length > 0) {
                       $(this).parents('.form-group').removeClass('empty-dropdown');
                   } else {
                       $(this).parents('.form-group').addClass('empty-dropdown');
                   }
                } else {
                    $(this).parents('.form-group').addClass('empty-dropdown');
                }
            });

            // checks validation of input after leaving it
            // $(document).on('focusout','.portal-styles input', function (e) {
            //     $(this).valid();
            // });

            $(document).on('change','.js-part-section-toggle', function (e) {
                var $part_to_show = $(document).find("[data-part-section-id='" + $(this).attr('data-part-section') + "']").toggleClass('enabled');

                if($part_to_show.hasClass('enabled')) {
                    $part_to_show.find('select, input').each(function() {
                        // For material fields dont remove disabled if it has already been filled in
                        if($(this).hasClass('js-material-field')) {
                            var $material_field = $(this).parents('.js-material-selection-holder').next('.js-material-selection-field-container').addClass('hidden');

                            if(!$material_field.hasClass('filled')) {
                                $(this).removeAttr("disabled");
                            }
                        } else {
                            $(this).removeAttr("disabled");
                        }
                    })
                } else {
                    $part_to_show.find('select, input').attr("disabled", 'disabled');
                }
            });

            $(document).on('change','.js-dimensions-selector', function (e) {
                var width_field = $(document).find(".js-custom-width-container");
                var depth_field = $(document).find(".js-custom-depth-container");

               if($(this).val() == 'CUSTOM') {
                   width_field.removeClass('hidden').find('select, input').removeAttr("disabled");
                   depth_field.removeClass('hidden').find('select, input').removeAttr("disabled");
               } else {
                   width_field.addClass('hidden').find('select, input').attr("disabled", 'disabled');
                   depth_field.addClass('hidden').find('select, input').attr("disabled", 'disabled');
               }
            });

            $(document).on('change','.js-material-selection-field', function (e) {
                var cost_center_code = $(this).val();
                var cost_center_options = JSON.parse($(this).attr('data-material-options'));

                // reset to not showing or working
                var $material_field = $(this).parents('.js-material-selection-holder').next('.js-material-selection-field-container').removeClass('filled').removeClass('empty-dropdown').addClass('hidden');
                var $drop_down = $material_field.find('.nice-select');
                $drop_down.niceSelect('destroy');
                $drop_down.find('option').remove().end();
                $drop_down.attr("disabled", 'disabled');
                $material_field.removeClass('always-hidden');

                for (let i = 0; i < cost_center_options.length; i++) {
                    var cost_center = cost_center_options[i];

                    // The selected cost center has materials that need to be shown
                    if(cost_center['cost_center_code'] == cost_center_code) {

                        var material_is_required = $(this).attr('required');
                        if (typeof material_is_required !== 'undefined' && material_is_required !== false) {
                            $drop_down.append($('<option>').attr('value', '').text('None'));
                            $drop_down.parents('.form-group').addClass('empty-dropdown');
                        } else {
                            $drop_down.attr('required', 'required');
                            $drop_down.parents('.form-group').addClass('filled');
                        }

                        var materials = cost_center['options'];
                        var count = 0;
                        for (var key in materials) {
                            count ++;

                            var $option = $('<option>').attr('value', key).text(materials[key]);

                            if(cost_center['default_value'] == key) {
                                $option.attr('selected', 'selected');
                                $drop_down.parents('.form-group').addClass('filled');
                                $drop_down.parents('.form-group').removeClass('empty-dropdown');
                            }

                            $drop_down.append($option);
                        }

                        // If only 1 option and its defaulted then stay hidden
                        if(count == 1) {
                            $material_field.addClass('always-hidden');
                        }

                        $drop_down.removeAttr("disabled");
                        $drop_down.niceSelect();
                        $material_field.removeClass('hidden');
                    }
                }

                if($(this).attr('type') == 'checkbox') {
                    if($(this).is(":checked")) {
                        $material_field.removeClass('hidden').find('select, input').removeAttr("disabled");
                    } else {
                        $material_field.addClass('hidden').find('select, input').attr("disabled", 'disabled');
                    }
                }
            });

            $(document).on('change','.js-toggle-value-field', function (e) {
                var cost_center_code = $(this).val();
                var $value_field = $(this).parents('.js-value-selection-holder').next('.js-value-selection-field-container');

                // Finds the value field if it appears after a material field
                if($value_field && !$value_field.hasClass('js-value-selection-field-container')) {
                    $value_field = $(this).parents('.js-value-selection-holder').nextUntil('.js-value-selection-field-container').next();
                }

                // reset to not showing or working
                $value_field.removeClass('always-hidden');
                var $drop_down = $value_field.find('.nice-select');

                var show_field = true;

                if($(this).attr('type') == 'checkbox') {
                    if($(this).is(":checked")) {
                        $value_field.removeClass('hidden').find('select, input').removeAttr('disabled');
                    } else {
                        $value_field.addClass('hidden').find('select, input').attr('disabled', 'disabled');
                        show_field = false;
                    }
                } else {
                    if($(this).val() != '') {
                        $value_field.removeClass('hidden').find('select, input').removeAttr('disabled');
                    } else {
                        $value_field.addClass('hidden').find('select, input').attr('disabled', 'disabled');
                        show_field = false;
                    }
                }

                // Value field needs to show different options based on cost center
                var cost_center_options = $(this).attr('data-value-options');

                if (typeof(cost_center_options) != "undefined") {
                    // reset
                    $value_field.removeClass('filled').removeClass('empty-dropdown').addClass('hidden');
                    $drop_down.niceSelect('destroy');
                    $drop_down.find('option').remove().end();
                    $drop_down.attr("disabled", 'disabled');

                    cost_center_options = JSON.parse(cost_center_options);

                    for (let i = 0; i < cost_center_options.length; i++) {
                        var cost_center = cost_center_options[i];

                        // The selected cost center has materials that need to be shown
                        if(cost_center['cost_center_code'] == cost_center_code) {

                            var value_is_required = $(this).attr('required');
                            if (typeof value_is_required !== 'undefined' && value_is_required !== false) {
                                $drop_down.append($('<option>').attr('value', '').text('None'));
                                $drop_down.parents('.form-group').addClass('empty-dropdown');
                            } else {
                                $drop_down.attr('required', 'required');
                                $drop_down.parents('.form-group').addClass('filled');
                            }

                            var values = cost_center['options'];
                            var count = 0;
                            for (var key in values) {
                                count ++;

                                var $option = $('<option>').attr('value', key).text(values[key]);

                                if(cost_center['default_value'] == key) {
                                    $option.attr('selected', 'selected');
                                    $drop_down.parents('.form-group').addClass('filled');
                                    $drop_down.parents('.form-group').removeClass('empty-dropdown');
                                }

                                $drop_down.append($option);
                            }

                            // If only 1 option and its defaulted then stay hidden
                            if(count == 1) {
                                $value_field.addClass('always-hidden');
                            }

                            $drop_down.removeAttr("disabled");
                            $drop_down.niceSelect();
                            $value_field.removeClass('hidden');
                        }
                    }
                }

                if($drop_down && show_field) {
                    $drop_down.niceSelect('destroy');
                    $drop_down.removeAttr("disabled");
                    $drop_down.niceSelect();
                }
            });

            $(document).on('click','.js-product-selector', function (e) {
                // If on portal page change product else let normal redirect happen
                if($(document).find(".js-portal-details-container").length) {
                    e.preventDefault();
                    var product_id = $(this).attr('data-pid');
                    if(product_loaded_id == null) {
                        loadProduct(product_id, true);
                    } else {
                        $('body').addClass('display-dialog');
                        $("#js-confirm-product-change").dialog({
                            resizable: false,
                            height: "auto",
                            minWidth: 370,
                            modal: true,
                            draggable: false,
                            buttons: {
                                "Confirm Selection": function() {
                                    $(this).dialog("close");
                                    $('body').removeClass('display-dialog');

                                    loadProduct(product_id, true);
                                },
                                Cancel: function() {
                                    $(this).dialog("close");
                                    $('body').removeClass('display-dialog');
                                    e.preventDefault();
                                }
                            }
                        });
                    }
                }
            });

            $(document).on('click','.js-product-list-toggle', function (e) {
                e.preventDefault();

                $(this).toggleClass('open');
                $('.js-product-list-container').toggleClass('open');
            });

            $(document).on('click','.js-show-product-search', function (e) {
                e.preventDefault();

                $('.js-find-product-container').toggleClass('open');
            });

            $(document).on('click','.js-delete-estimate', function (e) {
                e.preventDefault();
                var $button = $(this);

                $("#js-confirm-delete-estimate").dialog({
                    resizable: false,
                    height: "auto",
                    minWidth: 370,
                    modal: true,
                    draggable: false,
                    buttons: {
                        "Delete Item": function() {
                            $(this).dialog("close");
                            $('body').removeClass('display-dialog');

                            deleteEstimate($button).then(function(data) {
                                console.log(data);
                            }).catch(function(err) {
                                // Run this when promise was rejected via reject()
                                console.log(err)
                            }).finally(function(err) {

                            });
                        },
                        Cancel: function() {
                            $(this).dialog("close");
                            $('body').removeClass('display-dialog');
                            e.preventDefault();
                        }
                    }
                });
            });

            function deleteEstimate ($button) {
                var estimate_id = $button.attr('data-estimate-id');

                return new Promise(function(resolve, reject) {
                    if(estimate_id && estimate_id.length > 0) {
                        $.request('PrintPortalExtras::onDeleteEstimate', {
                            data: {'estimate_id': [estimate_id]},
                            update: {
                                'portal/pages/my_estimates_results': '#results'
                            },
                            success: function (data) {
                                updatePagination();
                                $.oc.flashMsg({
                                    'text': 'Successfully deleted estimate',
                                    'class': 'success',
                                    'interval': 3
                                });
                                this.success(data);
                                resolve(data);
                            },
                            error: function (data) {
                                $.oc.flashMsg({
                                    'text': 'Error deleted estimate. Code 10.',
                                    'class': 'error',
                                    'interval': 3
                                });
                                reject(data);
                            }
                        })
                    }
                });
            }

            // External button used to submit form to generate estimate
            $(document).on('click','#generate-product-estimate', function (e) {
                e.preventDefault();

                // Remove error messages as for some reason multiple were being generated
                $("#title").siblings('.form-field-error-label').remove();
                $("#validation-errors").removeClass('active');
                $('#submission-errors').removeClass('active-errors').html('');

                var form_is_valid = $("#product-estimate-form").valid();
                var title_is_valid = $("#title").valid();

                if(!form_is_valid || !title_is_valid) {

                    // TODO: Does not work as the section is scrollable = mmake it swcroll that seciton and not the whole page
                    // $('html, body').animate({scrollTop: $('.has-error').first().offset().top - 100}, 500, function () {
                    //     $('.has-error').first().focus();
                    // });
                    var form_validator = helpers.validateFields($("#product-estimate-form"));
                    var error_count = form_validator.numberOfInvalids();

                    $.oc.flashMsg({
                        'text': 'There ' + (error_count == 1 ? 'is' : 'are') + ' ' + error_count + ' error' + (error_count == 1 ? '' : 's') + ' in the form. Please fix these errors first',
                        'class': 'error',
                        'interval': 3
                    });

                } else {
                    generateEstimate().then(function(data) {
                        console.log(data);
                    }).catch(function(err) {
                        // Run this when promise was rejected via reject()
                        console.log(err)
                    }).finally(function(err) {
                        $('body').removeClass('loading');
                        $('#generate-product-estimate').removeAttr('disabled').removeClass('loading');
                    });
                }
            });

            // External button used to calculate shipping
            $(document).on('click','#calculate-shipping-cost', function (e) {
                e.preventDefault();

                var form_is_valid = $("#shipping-calculator-form").valid();
                var $post_code = $("#post_code").val();

                if(!form_is_valid || $post_code.length != 4) {
                    $.oc.flashMsg({
                        'text': 'Please enter a valid postcode in order to calculate shipping',
                        'class': 'error',
                        'interval': 3
                    });
                } else {
                    calculateShippingCost().then(function(data) {
                        console.log(data);
                    }).catch(function(err) {
                        // Run this when promise was rejected via reject()
                        console.log(err)
                    }).finally(function(err) {
                        $('body').removeClass('loading');
                    });
                }
            });

            $(document).on('change','.js-portal-field-onchange-dropdown', function (e) {
                fieldHasChanged();
            });

            $(document).on('change','.js-portal-field-onchange-checkbox', function (e) {
                fieldHasChanged();
            });

            $(document).on('input','.js-portal-field-onchange-text', function (e) {
                fieldHasChanged();
            });
        });

        function fieldHasChanged() {
            $('#generate-product-estimate').removeClass('thin small');
            $('#add-to-cart').addClass('secondary').attr('disabled', 'disabled');
        }

        // Used for initial set up of portal
        function loadPortal() {
            var product_id = helpers.getUrlParameter('pid');

            if(product_id && product_id.length > 0) {
                loadProduct(product_id, false, false);
            } else {
                $('.js-find-product-container').addClass('open');
            }
        }

        // Loads a product and displays it on screen
        function loadProduct (product_id, ignore_prefill, update_url = true) {
            getProduct(product_id, ignore_prefill, update_url).then(function(data) {
                console.log(data);
            }).catch(function(err) {
                // Run this when promise was rejected via reject()
                console.log(err)
            }).finally(function(err) {
                $('body').removeClass('loading');

                // Clear old validator
                var validator = $("#product-estimate-form").validate();
                if(validator) {
                    validator.destroy();
                }
                var validator = $("#title").validate();
                if(validator) {
                    validator.destroy();
                }

                // Put in new one
                var form_validator = helpers.validateFields($("#product-estimate-form"));
                var title_validator = helpers.validateFields($("#title"));
            });
        }

        function getProduct (product_id, ignore_prefill, update_url = false) {
            $('body').addClass('loading');

            $('.js-product-list-container').addClass('open');
            var old_open_menu = $(document).find(".js-product-selector.open").removeClass('open');
            $(document).find("[data-pid='" + product_id + "']").addClass('open');

            return new Promise(function(resolve, reject) {
                var error_message = '';
                $('#submission-errors').removeClass('active-errors').html('');

                if(product_id && product_id.length > 0) {

                    var send_data = {
                        'product_id': product_id
                    };

                    // If we are loading another product after one is prefilled dont pre fill the next one
                    if(!ignore_prefill) {
                        var action_type = helpers.getUrlParameter('action');
                        if(action_type && action_type.length > 0) {
                            send_data['action_type'] = action_type;
                        }
                    }

                    var order_item_id = helpers.getUrlParameter('oid');
                    if(order_item_id && order_item_id.length > 0) {
                        send_data['order_item_id'] = order_item_id;
                    }
                    var estimate_id = helpers.getUrlParameter('eid');
                    if(estimate_id && estimate_id.length > 0) {
                        send_data['estimate_id'] = estimate_id;
                    }
                    var cart_item_id = helpers.getUrlParameter('cid');
                    if(cart_item_id && cart_item_id.length > 0) {
                        send_data['cart_item_id'] = cart_item_id;
                    }
                    var template_id = helpers.getUrlParameter('tid');
                    if(template_id && template_id.length > 0) {
                        send_data['template_id'] = template_id;
                    }

                    $.request('PrintPortal::onGetProductDetails', {
                        data: send_data,
                        update: {
                            'portal/product_details': '#product-details-container',
                            'portal/estimate_details': '#js-estimate-details-wrapper'
                        },
                        success: function (result_data) {
                            if (result_data['valid_product']) {
                                // Enable navigation prompt to say they will lose set up if they navigate away
                                window.onbeforeunload = function() {
                                    return true;
                                };

                                product_loaded_id = result_data.product.id;
                                $('#title').val('');
                                $('.js-find-product-container').removeClass('open');
                                $('.js-product-list-container').addClass('open');
                                $('.js-product-list-toggle').addClass('open');

                                // Update URL
                                if(update_url) {
                                    var pageUrl = '?pid=' + result_data.product_id;
                                    window.history.pushState('', '', pageUrl);
                                }

                                this.success(result_data);
                                resolve(result_data);
                            } else {
                                $(document).find("[data-pid='" + product_id + "']").removeClass('open');
                                if(old_open_menu.length) {
                                    old_open_menu.addClass('open');
                                }

                                if (result_data['error_message'] !== undefined) {
                                    $.oc.flashMsg({
                                        'text': result_data['error_message'],
                                        'class': 'error',
                                        'interval': 3
                                    });
                                }
                                reject(result_data);
                            }
                        },
                        error: function (data) {
                            // Remove navigation prompt
                            window.onbeforeunload = null;

                            error_message = 'Error loading product. Code 17.'

                            $(document).find("[data-pid='" + product_id + "']").removeClass('open');
                            if(old_open_menu.length) {
                                old_open_menu.addClass('open');
                            }

                            $.oc.flashMsg({
                                'text': error_message,
                                'class': 'error',
                                'interval': 3
                            });

                            reject(data);
                        }
                    })
                }
            });
        }

        function generateEstimate () {
            $('body').addClass('loading');
            $('#generate-product-estimate').attr('disabled', 'disabled').addClass('loading');

            return new Promise(function(resolve, reject) {
                var error_message = '';

                var form_data = $('#product-estimate-form').serializeArray().reduce(function(obj, item) {
                    obj[item.name] = item.value;
                    return obj;
                }, {});

                // include disabled fields in serialize list
                $('#product-estimate-form input:disabled,select:disabled').each(function () {
                    form_data[this.name] = $(this).val();
                });

                $.request('PrintPortal::onGenerateEstimate', {
                    data: form_data,
                    update: {
                        'portal/estimate_details': '#js-estimate-details-wrapper',
                    },
                    success: function (result_data) {
                        if (result_data['estimate_generated']) {

                            $.oc.flashMsg({
                                'text': 'Estimate Created',
                                'class': 'success',
                                'interval': 3
                            });

                            this.success(result_data);
                            resolve(result_data);
                        } else {
                            if (result_data['error_message'] !== undefined) {
                                $.oc.flashMsg({
                                    'text': result_data['error_message'],
                                    'class': 'error',
                                    'interval': 3
                                });
                            }

                            if (result_data['errors_to_fix'] !== undefined) {
                                $('#submission-errors').addClass('active-errors').html('');

                                for (var i = 0; i < result_data['errors_to_fix'].length; i++) {
                                    var error_message = document.createElement('span');
                                    error_message.innerText = result_data['errors_to_fix'][i];

                                    $('#submission-errors').append(error_message);
                                }
                            } else {
                                $('#submission-errors').removeClass('active-errors').html('');
                            }

                            reject(result_data);
                        }
                    },
                    error: function (data) {
                        error_message = 'Error obtaining estimate. Code 50.'

                        $.oc.flashMsg({
                            'text': error_message,
                            'class': 'error',
                            'interval': 3
                        });

                        reject(data);
                    }
                })
            });
        }

        function calculateShippingCost () {
            $('body').addClass('loading');

            return new Promise(function(resolve, reject) {
                var error_message = '';

                var form_data = $('#shipping-calculator-form').serializeArray().reduce(function(obj, item) {
                    obj[item.name] = item.value;
                    return obj;
                }, {});

                $.request('PrintPortal::onGetShippingCost', {
                    data: form_data,
                    update: {
                        'portal/estimate_shipping_cost_details': '#js-courier-cost-container',
                    },
                    success: function (result_data) {
                        if (result_data['shipping_calculated']) {
                            this.success(result_data);
                            resolve(result_data);
                        } else {
                            if (result_data['error_message'] !== undefined) {
                                $.oc.flashMsg({
                                    'text': result_data['error_message'],
                                    'class': 'error',
                                    'interval': 3
                                });
                            }
                            reject(result_data);
                        }
                    },
                    error: function (data) {
                        error_message = 'Error obtaining shipping cost. Code 31.'
                        $.oc.flashMsg({
                            'text': error_message,
                            'class': 'error',
                            'interval': 3
                        });
                        reject(data);
                    }
                })
            });
        }

        function blockMobileScreens() {
            if($('.js-portal-mobile-block').length) {
                if($(window).width() < 1024) {
                    $('body').addClass('mobile-block');
                } else {
                    $('body').removeClass('mobile-block');
                }
            }
        }

        // https://jsfiddle.net/emkey08/tvx5e7q3
        $.fn.inputFilter = function(inputFilter) {
            return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
                if (inputFilter(this.value)) {
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                } else {
                    this.value = "";
                }
            });
        };

        window.addEventListener("orientationchange", function() {
            blockMobileScreens();
        }, false);

        window.addEventListener("resize", function() {
            blockMobileScreens();
        }, false);

        window.onpopstate = function(e){
            loadPortal();
        };
    };

    return {
        init:init
    };
};

export default Portal;