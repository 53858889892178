"use strict";

import {PublisherInterface} from "@chili-publish/publisher-interface";

const Chili = () => {
    const init = () => {

        $(document).ready(function() {
            (async () => {
                const iframe = document.getElementById("chili-frame");
                const publisher = await PublisherInterface.build(iframe);

                await publisher.addListener("DocumentFullyRendered", () => {
                    $('.js-chili-buttons').addClass('show');

                    // Set up autosave to run
                    setInterval(() => saveDocument(publisher), 60*1000); // run every min

                    $(document).on('click','.js-save-chili-document', function (e) {
                        e.preventDefault();

                         disableButton().then(function(data) {
                            saveDocument(publisher, true).then(function(data) {
                                console.log(data);

                                window.top.$(".js-existing-chili-preview-" + data['estimate']['id']).remove();
                                window.top.$(data['chili_added_html']).insertAfter('.js-chili-open-' + data['estimate']['id']);
                                window.top.$.fancybox.close();

                                window.top.$.oc.flashMsg({
                                    'text': 'Document composed successfully.',
                                    'class': 'success',
                                    'interval': 3
                                });

                                // Cart is ready so enable go to shipping button
                                if(data['document_ready_for_shipping']) {
                                    window.top.$('#go-to-shipping').removeClass('disabled').removeAttr("disabled");
                                }
                            }).catch(function(err) {
                                // Run this when promise was rejected via reject()
                                console.log(err)
                            }).finally(function(err) {
                                $('.js-save-chili-document').removeClass('loading');
                                $('.js-save-chili-document').attr('disabled', false);
                            });
                        }).catch(function(err) {
                            console.log(err)
                        })
                    });
                });
            })();
        });

        function disableButton() {
            $('.js-save-chili-document').addClass('loading');
            $('.js-save-chili-document').attr('disabled', true);

            return new Promise(function(resolve, reject) {
                resolve();
            });
        }

        async function saveDocument(publisher, manual_save = false) {
            const documentXml = await publisher.executeFunction("document", "GetTempXML");
            console.log('Saved');

            const result = await new Promise(function(resolve, reject) {
                $.request('ChiliProcessor::onSaveDocument', {
                    data: {
                        'document_xml' : documentXml,
                        'manual_save' : manual_save
                    },
                    success: function (result_data) {
                        if (result_data['document_saved']) {
                            this.success(result_data);
                            resolve(result_data);
                        } else {
                            $.oc.flashMsg({
                                'text': 'Error Saving Document. Please try again. Code 42',
                                'class': 'error',
                                'interval': 3
                            });
                            reject(result_data);
                        }
                    },
                    error: function (data) {
                        $.oc.flashMsg({
                            'text': 'Error Saving Document. Please try again. Code 41',
                            'class': 'error',
                            'interval': 3
                        });
                        reject(data);
                    }
                })
            });

            return result;
        }
    };

    return {
        init:init
    };
};

export default Chili;